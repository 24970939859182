import StringWidget from './components/StringWidget';
import NumberWidget from './components/NumberWidget';
import DateWidget from './components/DateWidget';
import BooleanWidget from './components/BooleanWidget';
import SelectWidget from './components/SelectWidget';
import countries from './countries';

export function checkWidget(fieldType){
    switch(fieldType){
        case "text":
            return StringWidget;
        case "number":
            return NumberWidget;
        case "date":
            return DateWidget;
        case "checkbox":
            return BooleanWidget;
        default:
            return StringWidget;
            
    }
}

export const name = {key: 'name', labelId: 'Name', widget: StringWidget};
export const age = {key: 'age', labelId: 'Age', widget: NumberWidget};
export const dob = {key: 'dob', labelId: 'Date of birth', widget: DateWidget};
export const married = {key: 'married', labelId: 'Married', widget: BooleanWidget};
export const country = {key: 'country', labelId: 'Country', widget: SelectWidget, widgetOptions: {options: countries}};
