export default {
    defaultPath: process.env.REACT_APP_DEFAULT_URI,
    basename: process.env.REACT_APP_PUBLIC_URI, // only at build time to set, like /datta-able
    layout: 'vertical', // vertical, horizontal (not available in lite version)
    preLayout: null, // (not available in lite version)
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, (menu-light, dark are not available in lite version)
    navIconColor: false,
    headerBackColor: 'header-default', // header-default, (header-blue, header-red, header-purple, header-lightblue, header-dark are not available in lite version)
    navBackColor: 'navbar-default', // navbar-default, (navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark are not available in lite version)
    navBrandColor: 'brand-default', // brand-default, (brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark are not available in lite version)
    navBackImage: false, // not available in lite version
    rtlLayout: false, // not available in lite version
    navFixedLayout: true,
    headerFixedLayout: false, // not available in lite version
    boxLayout: false,
    navDropdownIcon: 'style1', // style1, (style2, style3 are not available in lite version)
    navListIcon: 'style1', // style1, (style2, style3, style4, style5, style6 are not available in lite version)
    navActiveListColor: 'active-default', // active-default, (active-blue, active-red, active-purple, active-lightblue, active-dark are not available in lite version)
    navListTitleColor: 'title-default', // title-default, (title-blue, title-red, title-purple, title-lightblue, title-dark are not available in lite version)
    navListTitleHide: false, // not available in lite version
    configBlock: false, // not available in lite version
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
    layout6BackSize: '', // used only for pre-layout = layout-6    

    prevPath: process.env.REACT_APP_PUBLIC_URI,
    authentication: {
        instance: process.env.REACT_APP_AUTHENTICATION_INSTANCE,  // optional, will default to this
        tenant: process.env.REACT_APP_AUTHENTICATION_TENANT, // your B2C tenant
        signInPolicy: process.env.REACT_APP_AUTHENTICATION_SIGN_IN_POLICY, // the policy to use to sign in, can also be a sign up or sign in policy
        clientId: process.env.REACT_APP_AUTHENTICATION_CLIENT_ID, // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
        cacheLocation: process.env.REACT_APP_AUTHENTICATION_CACHE_LOCATION, // where MSAL will store state - localStorage or sessionStorage
        scopes: process.env.REACT_APP_AUTHENTICATION_SCOPES, // the scopes you want included in the access token
        codeVerifier: process.env.REACT_APP_AUTHENTICATION_CODE_VERIFIER,
        codeChallenge: process.env.REACT_APP_AUTHENTICATION_CODE_CHALLENGE, // Generated by https://tonyxu-io.github.io/pkce-generator/
        codeChallengeMethod: process.env.REACT_APP_AUTHENTICATION_CODE_CHALLENGE_METHOD,
        redirectUri: `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_PUBLIC_URI}${process.env.REACT_APP_AUTH_URI}`, // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
        postLogoutRedirectUri: `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_PUBLIC_URI}${process.env.REACT_APP_AUTH_URI}`,
        signOutRedirectUri: process.env.REACT_APP_AUTHENTICATION_SIGN_OUT_REDIRECT_URI,
    },

    apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
    apiFields: JSON.parse(localStorage.getItem('apiFields')) || {},
    systemParam: JSON.parse(localStorage.getItem('systemParam')) || {},
    apiBody: {
        pageNo: 1,
        pageSize: 10
    },
    regionCode: localStorage.getItem("regionCode"),
    languageCode: localStorage.getItem("languageCode"),
    regions: [
        "HK",
        "MMR",
        "LAO",
        "KH",
        "VN",
    ],
    languages: [
        "en",
        "zh-HK",
        "zh-CN",
        "lao",
        "mmr",
        "kh",
        "vn",
    ],
    defaultLanguages: [
        { "HK": "zh-HK" },
        { "MMR": "mmr" },
        { "LAO": "lao" },
        { "KH": "kh" },
        { "VN": "vn" },
    ],
    weekdays: {
        1: "sun",
        2: "mon",
        3: "tue",
        4: "wed",
        5: "thu",
        6: "fri",
        7: "sat",
    },
    local: 'en-US',
    timezone: 'Europe/London',
    inputType: [
        "date",
        "text",
        "checkbox",
        "single",
        "autoComplete",
        "number",
        "image",
        "rating",
    ],
};
