import React from 'react';
import { withTranslation } from 'react-i18next';
import DefaultWidget from './DefaultWidget';
import * as operators from '../operators';

class BooleanWidget extends DefaultWidget {

  renderInputElement(value, onChange) {
    const {t} = this.props;
    return (
      <label>
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)} />
          &nbsp;{t('True')}
      </label>
    );
  }
}

BooleanWidget.defaultOperator = operators.EQUALS;
BooleanWidget.defaultValue = false;

export default withTranslation()(BooleanWidget);
