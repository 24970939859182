import { withTranslation } from 'react-i18next';
import DefaultWidget from './DefaultWidget';
import * as operators from '../operators';

class StringWidget extends DefaultWidget {
  getOperators() {
    const {t} = this.props;
    return {
      [operators.CONTAINS]: t('contains'),
      // [operators.DOES_NOT_CONTAIN]: 'doesn\'t contains',
      // [operators.EQUALS]: '=',
      // [operators.NOT_EQUALS]: 'is not'
    };
  }
}

StringWidget.defaultOperator = operators.CONTAINS;
StringWidget.defaultValue = '';

export default withTranslation()(StringWidget);
