import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../store/actionCreators';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class AlertModal extends Component {
    render() {
        const { actions, t } = this.props;
        const onHide = this.props.onHide
            ? this.props.onHide
            : actions.hideAlertModal;
        const onHide2 = this.props.onHide ? 1 : 2;

        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={() => onHide()}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        {this.props.options ?
                            (this.props.options.map((option, key) => {
                                return (
                                    <Button key={`modal_option_${key}`} variant="primary" onClick={option.function}>
                                        {option.label}
                                    </Button>
                                )
                            }))
                            :
                            <Button variant="primary" onClick={() => onHide()}>
                                {t('close')}
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(AlertModal));
