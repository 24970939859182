import axios from 'axios';
import helpers from '../helpers';
import config from '../../config';

const interceptor = axios.create();

// Request interceptor for API calls
interceptor.interceptors.request.use(
    async (apiConfig) => {
        apiConfig.headers = helpers.getApiHeader();
        if (!apiConfig.headers) {
            return;
        }

        return apiConfig;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Response interceptor for API calls
interceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if (!localStorage.getItem('refresh_token')) {
            helpers.relogin();
            return
        }

        if (!error.response) {
            throw 'api_issue';
        }

        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const token = localStorage.getItem('refresh_token');
            const authenticationData = config.authentication;
            const url =
                authenticationData.instance +
                authenticationData.tenant +
                '/' +
                authenticationData.signInPolicy +
                '/oauth2/v2.0/token?client_id=' +
                authenticationData.clientId +
                '&refresh_token=' +
                token +
                '&grant_type=refresh_token';

            return axios
                .get(url)
                .then((res) => {
                    localStorage.setItem('id_token', res.data.id_token);
                    localStorage.setItem(
                        'refresh_token',
                        res.data.refresh_token
                    );

                    return interceptor(originalRequest);
                })
                .catch((error) => {
                    alert('Token expired');
                    helpers.relogin();
                });
        }
        return Promise.reject(error);
    }
);

export default interceptor;
