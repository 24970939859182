import moment from 'moment';
import config from '../config';

const helpers = {
    isDate: function (date) {
        return moment(date, true).isValid();
    },

    convertDateTimeToLocalDate: function (dateTime) {
        return moment(dateTime).local().format('YYYY-MM-DD HH:mm:ss');
    },

    convertTimeToUtc: function (time) {
        return moment(time, 'HH:mm').utc().format('HH:mm');
    },

    convertTextareaToArray: function (fields, data) {
        fields.map((value, key) => {
            if (value.fieldType === 'text' && value.multiple) {
                let textValue = value.key.split('.');
                if (textValue[1] !== undefined) {
                    data[textValue[0]][textValue[1]] =
                        data[textValue[0]][textValue[1]] !== undefined && data[textValue[0]][textValue[1]] !== null
                            ? Array.isArray(data[textValue[0]][textValue[1]])
                                ? data[textValue[0]][textValue[1]]
                                : data[textValue[0]][textValue[1]].split('\n')
                            : null;
                } else {
                    data[textValue[0]] =
                        data[textValue[0]] !== undefined && data[textValue[0]] !== null
                            ? Array.isArray(data[textValue[0]])
                                ? data[textValue[0]]
                                : data[textValue[0]].split('\n')
                            : null;
                }
            }
        });
    },
    convertArrayToTextarea: function (fields, data) {
        fields.map((value, key) => {
            if (value.fieldType === 'text' && value.multiple) {
                let textValue = value.key.split('.');
                if (textValue[1] !== undefined) {
                    data[textValue[0]][textValue[1]] =
                        data[textValue[0]][textValue[1]] !== null
                            ? Array.isArray(data[textValue[0]][textValue[1]])
                                ? data[textValue[0]][textValue[1]]
                                : data[textValue[0]][textValue[1]].split('\n')
                            : data[textValue[0]][textValue[1]]
                } else {
                    data[textValue[0]] =
                        data[textValue[0]] !== null
                            && Array.isArray(data[textValue[0]])
                            ? data[textValue[0]]
                            : data[textValue[0]].split('\n')
                }
            }
        });
    },

    convertDateToDateString: function (fields, data) {
        fields.map((value, key) => {
            if (value.fieldType === 'date') {
                if (value.key.split('.')[1] !== undefined) {
                    data[value.key.split('.')[0]][value.key.split('.')[1]] =
                        moment(
                            moment(
                                data[value.key.split('.')[0]][
                                value.key.split('.')[1]
                                ]
                            ),
                            true
                        ).isValid()
                            ? moment(
                                data[value.key.split('.')[0]][
                                value.key.split('.')[1]
                                ]
                            ).startOf('day').toDate()
                            : '';
                } else {
                    data[value.key] = moment(
                        moment(data[value.key]),
                        true
                    ).isValid()
                        ? moment(data[value.key]).startOf('day').toDate()
                        : '';
                }
            } else if (value.fieldType === 'time') {
                if (value.key.split('.')[1] !== undefined) {
                    data[value.key.split('.')[0]][value.key.split('.')[1]] =
                        moment(data[value.key.split('.')[0]][value.key.split('.')[1]]).format('HH:mm');
                } else {
                    data[value.key] =
                        moment(data[value.key]).format('HH:mm');
                }
            }
        });
    },

    convertDateStringToDateByType: function (string, type = 'date') {
        if (type === 'date') {
            return moment(string).toDate();
        }
        return moment(string, 'HH:mm').toDate();
    },

    convertDateStringToDate: function (fields, data) {
        fields.map((value, key) => {
            if (value.fieldType === 'date' || value.fieldType === 'time') {
                if (value.key.split('.')[1] !== undefined) {
                    data[value.key.split('.')[0]][value.key.split('.')[1]] =
                        moment(
                            this.convertDateStringToDateByType(
                                data[value.key.split('.')[0]][
                                value.key.split('.')[1]
                                ],
                                value.fieldType
                            ),
                            true
                        ).isValid() && data[value.key.split('.')[0]][value.key.split('.')[1]] !== undefined
                            ? this.convertDateStringToDateByType(
                                data[value.key.split('.')[0]][
                                value.key.split('.')[1]
                                ],
                                value.fieldType
                            )
                            : '';
                } else {
                    data[value.key] = moment(
                        this.convertDateStringToDateByType(
                            data[value.key],
                            value.fieldType
                        ),
                        true
                    ).isValid() && data[value.key] !== undefined
                        ? this.convertDateStringToDateByType(
                            data[value.key],
                            value.fieldType
                        )
                        : '';
                }
            }
        });
    },

    removeLocalStorage: function () {
        localStorage.clear();
        localStorage.setItem('prevPath', '/');
    },

    relogin: function () {
        this.removeLocalStorage();
        window.location.href = config.authentication.redirectUri;
    },

    getApiHeader: function (contentType = 'application/json') {
        if (!localStorage.getItem('id_token')) {
            return;
        }

        return {
            Authorization: 'Bearer ' + localStorage.getItem('id_token'),
            'Content-Type': contentType,
            regionCode: localStorage.getItem('regionCode'),
        };
    },

    getTokenDetails: function (token) {
        if (!token || !token.split('.')[1]) {
            return {};
        }

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    },

    getArrayItemsFromLocalStorage: function (key) {
        if (!localStorage.getItem('roles')) {
            return [];
        }
        return localStorage.getItem(key).split(',');
    },

    checkRoleWithString: function (role) {
        const roleArray = this.getArrayItemsFromLocalStorage('roles');
        return roleArray.includes(role);
    },

    checkRightWithString: function (role) {
        const roleArray = this.getArrayItemsFromLocalStorage('rights');
        return roleArray.includes(role);
    },

    checkSystemParamWithString: function (systemParam) {
        const SystemParamList = JSON.parse(localStorage.getItem('systemParam'));
        if (!SystemParamList) {
            return [];
        }
        return SystemParamList[systemParam];
    },

    checkRight: function (section, feature) {
        let sectionString = '';
        let featureString = '';

        switch (section) {
            case 'user':
                sectionString = 'USER';
                break;
            case 'userRole':
                sectionString = 'USER_ROLE';
                break;
            case 'userTeam':
                sectionString = 'USER_TEAM';
                break;
            case 'brand':
                sectionString = 'BRAND';
                break;
            case 'category':
                sectionString = 'CATEGORY';
                break;
            case 'product':
                sectionString = 'PRODUCT';
                break;
            case 'salesChannel':
                sectionString = 'SALES_CHANNEL';
                break;
            case 'customer':
                sectionString = 'CUSTOMER';
                break;
            case 'event':
                sectionString = 'EVENT';
                break;
            case 'openStock':
                sectionString = 'OPEN_STOCK';
                break;
            case 'playbook':
                sectionString = 'PLAY_BOOK';
                break;
            case 'posm':
                sectionString = 'POSM';
                break;
            case 'posmSetup':
                sectionString = 'POSM';
                break;
            case 'posmSubmitted':
                sectionString = 'POSM';
                break;
            case 'roster':
                sectionString = 'ROSTER';
                break;
            case 'salesInput':
                sectionString = 'SALES_INPUT';
                break;
            case 'promotion':
                sectionString = 'PROMOTION';
                break;
            case 'form':
                sectionString = 'FORM';
                break;
            case 'assessmentFormSetup':
                sectionString = 'FORM';
                break;
            case 'surveyFormSetup':
                sectionString = 'FORM';
                break;
            case 'formSubmitted':
                sectionString = 'FORM';
                break;
            case 'salesTarget':
                sectionString = 'SALES_TARGET';
                break;
            case 'salesAchievement':
                sectionString = 'SALES_ACHIEVEMENT';
                break;
            case 'assessment':
                sectionString = 'ASSESSMENT';
                break;
            default:
                break;
        }

        switch (feature) {
            case 'create':
                featureString = 'CREATE';
                break;
            case 'update':
                featureString = 'UPDATE';
                break;
            case 'search':
                featureString = 'READ';
                break;
            case 'show':
                featureString = 'READ';
                break;
            case 'delete':
                featureString = 'DELETE';
                break;
            case 'export':
                featureString = 'EXECUTE';
                break;
            case 'import':
                featureString = 'EXECUTE';
                break;
            case 'confirm':
                featureString = 'EXECUTE';
                break;
            case 'activate':
                featureString = 'ACTIVATE';
                break;
            case 'bulk':
                featureString = 'BULK';
                break;
            default:
                break;
        }

        const roleString = `WEB::${sectionString}::${featureString}`;
        return this.checkRightWithString(roleString);
    },

    getChildrenPermission: function (parent) {
        if (parent.systemParam) {
            if (!this.checkSystemParamWithString(parent.systemParam)) {
                return false;
            }
        }

        if (parent.checking === false) {
            return true;
        }

        if (parent.section && parent.feature) {
            if (this.checkRight(parent.section, parent.feature)) {
                return true;
            }
        }

        if (!parent.children) {
            return false;
        }

        let valid = false;
        for (let children of parent.children) {
            valid = this.getChildrenPermission(children);
            if (valid) {
                break;
            }
        }
        return valid;
    },

    checkRightOnPage: function (section, feature) {
        if (!this.checkRight(section, feature)) {
            localStorage.setItem('prevPath', JSON.stringify('/profile'));
            window.location.href = config.authentication.redirectUri;
        }
    },
    getGenderList: function () {
        return [
            {
                value: 'M',
                label: 'Male',
            },
            {
                value: 'F',
                label: 'Female',
            },
        ];
    },

    validateSubmitData: function (fields, data, type = 'create') {
        const errorData = [];
        fields.map((value, key) => {
            if (type === 'modify' && !value.editable) {
                return;
            }

            if (data.info && data.info.isAssessment === true && value.key === "user") {
                return;
            }

            const targetData =
                value.key.split('.')[1] !== undefined
                    ? data[value.key.split('.')[0]][value.key.split('.')[1]]
                    : data[value.key];

            if (value.required && (!targetData || targetData.length === 0)) {
                errorData.push({
                    key: value.key,
                    labelId: value.labelId,
                    messageId: 'error.required',
                });
            } else if (value.validation && (targetData || targetData.length !== 0)) {
                value.validation.forEach((rule) => {
                    const reg = new RegExp(rule.rule);
                    if (!reg.test(targetData)) {
                        errorData.push({
                            key: value.key,
                            labelId: value.labelId,
                            messageId: rule.errorId,
                        });
                    }
                });
            }
        });
        return errorData;
    },

    resetFormFieldsValue: function (fields, formData) {
        formData = {};
        fields.map((field, key) => {
            let fieldParent = field.key.split('.')[0];
            let fieldChild = field.key.split('.')[1];
            let fieldValue = "";

            switch (field.fieldType) {
                case "autocomplete":
                    if (field.multiple === true) {
                        fieldValue = null;
                    } else if (field.required === true) {
                        fieldValue = "";
                    }
                    break;
                case "checkbox":
                    fieldValue = false;
                    break;
                case "array":
                    fieldValue = [];
                    break;
            }

            if (fieldChild !== undefined) {
                if (formData[fieldParent] === undefined) {
                    formData[fieldParent] = {};
                }

                formData[fieldParent][fieldChild] = fieldValue;
            } else {
                formData[fieldParent] = fieldValue
            }
        });

        return formData;
    },
};

export default helpers;
