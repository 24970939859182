import React from 'react';
import { withTranslation } from 'react-i18next';
import DefaultWidget from './DefaultWidget';
import dateFormat from 'dateformat';
import * as operators from '../operators';
import moment from 'moment';
import DatePicker from 'react-datepicker';
 

class DateWidget extends DefaultWidget {
  getOperators() {
    const {t} = this.props;
    return {
      [operators.EQUALS]: t('on'),
      // [operators.GREATER_THAN]: 'after',
      // [operators.LESS_THAN]: 'before',
      // [operators.BETWEEN]: 'between',
      // [operators.NOT_BETWEEN]: 'not between'
    };
  }

  renderInputElement(value, onChange) {
    const onChangeWithDate = (date) => {
      const stringValue = date;
      onChange(stringValue === '' ? null : moment(stringValue).startOf('day').toDate());
    };

    return <DatePicker
                selected={value}
                onChange={onChangeWithDate}
                showMonthDropdown
                useShortMonthInDropdown
            />;
  }
}

DateWidget.defaultOperator = operators.EQUALS;
DateWidget.defaultValue = moment().startOf('day').toDate();

export default withTranslation()(DateWidget);
