export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';

export const UPDATE_API_FIELDS = 'UPDATE_API_FIELDS';
export const UPDATE_SYSTEM_PARAM = 'UPDATE_SYSTEM_PARAM';
export const UPDATE_API_TOKEN = 'UPDATE_API_TOKEN';
export const UPDATE_PREV_PATH = 'UPDATE_PREV_PATH';
export const UPDATE_HEADERS = 'UPDATE_HEADERS';

export const NEED_LOGIN = 'NEED_LOGIN';
export const API_ERROR = 'API_ERROR';

export const RESET_ERROR_LIST = 'RESET_ERROR_LIST';
export const UPDATE_ERROR_LIST = 'UPDATE_ERROR_LIST';
export const RESET_FORM_FIELD = 'RESET_FORM_FIELD';
export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';

export const CALL_API = 'CALL_API';
export const UPDATE_API = 'UPDATE_API';
export const UPDATE_API_BODY = 'UPDATE_API_BODY';
export const RESET_API_BODY = 'RESET_API_BODY';
export const UPDATE_API_PAGE_NUMBER = 'UPDATE_API_PAGE_NUMBER';
export const UPDATE_API_PAGE_SIZE = 'UPDATE_API_PAGE_SIZE';
export const UPDATE_API_RESULT = 'UPDATE_API_RESULT';
export const UPDATE_API_DATA = 'UPDATE_API_DATA';

export const UPDATE_REGION_CODE = 'UPDATE_REGION_CODE';
export const UPDATE_LANGUAGE_CODE = 'UPDATE_LANGUAGE_CODE';

export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';

export const SHOW_ALERT_MODAL = 'SHOW_ALERT_MODAL';
export const HIDE_ALERT_MODAL = 'HIDE_ALERT_MODAL';

export const MODEL_STATUS = 'MODEL_STATUS';
export const UPDATE_MODEL = 'UPDATE_MODEL';
export const RESET_MODEL = 'RESET_MODEL';

// Search Filter Type
export const SELECT_FIELD = 'SELECT_FIELD';
export const UNSELECT_FIELD = 'UNSELECT_FIELD';

export const UPDATE_AVAILABLE_FIELD = 'UPDATE_AVAILABLE_FIELD';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CHANGE_FILTER_FIELD = 'CHANGE_FILTER_FIELD';
export const CHANGE_FILTER_OPERATOR = 'CHANGE_FILTER_OPERATOR';
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE';
