export const EQUALS = 'EQUALS';
export const NOT_EQUALS = 'NOT_EQUALS';
export const GREATER_THAN = 'GREATER_THAN';
export const GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS';
export const LESS_THAN = 'LESS_THAN';
export const LESS_THAN_EQUALS = 'LESS_THAN_EQUALS';
export const BETWEEN = 'BETWEEN';
export const NOT_BETWEEN = 'NOT_BETWEEN';
export const LIKE = 'LIKE';
export const NOT_LIKE = 'NOT_LIKE';
export const CONTAINS = 'CONTAINS';
export const DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN';
