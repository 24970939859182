import './components/i18n';
import React, { Component, Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import '../../node_modules/font-awesome/scss/font-awesome.scss';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../route';
import AlertModal from './components/AlertModal';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader,
});

class App extends Component {
    render() {
        const menu = routes.map((route, index) => {
            return route.component ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                />
            ) : null;
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <AlertModal
                            show={this.props.showAlertModal}
                            title={this.props.alertModalTitle}
                            onHide={this.props.onHideAlertModal}
                            options={this.props.alertModalOptions}
                        />
                        <Switch>
                            {menu}
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showAlertModal: state.showAlertModal,
        alertModalTitle: state.alertModalTitle,
        onHideAlertModal: state.onHideAlertModal,
        alertModalOptions: state.alertModalOptions,
    };
};

export default withRouter(connect(mapStateToProps, null)(App));
