import i18n from '../App/components/i18n';
import config from './../config';
import * as actionTypes from './actions';

// Filter Imports
import * as fields from './../App/filter/fields';
import * as operators from './../App/filter/operators';

let nextId = 0;
const emptyFilters = [];
const availableFields = [
    fields.name,
    fields.age,
    fields.dob,
    fields.married,
    fields.country,
];

// Filter State
const filterState = {
    filters: [...emptyFilters],
    selectedFields: [...availableFields],
    availableFields,
};

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
    loading: false,
    needLogin: false,
    filterState,

    formData: {},
    errorList: [],

    apiUri: "",
    apiType: "",
    apiResult: "",

    modelBody: "",
    showModal: false,
};

function selectField(state, field) {
    return state.selectedFields.push(field);
}

function unselectField(state, field) {
    state.filters.splice(field, 1);
    state.selectedFields.splice(field, 1);
    return state;
}

function getFilter(field) {
    const { defaultOperator, defaultValue } = field.widget;
    return { field, operator: defaultOperator, value: defaultValue };
}

function addFilter(state) {
    state.filters[nextId++] = getFilter(state.selectedFields[0]);
    return state;
}

function removeFilter(state, filterId) {
    state.filters.splice(filterId, 1);
    nextId--;
    return state;
}

function clearFilters(state) {
    state.filters = [...emptyFilters];
    nextId = 0;
    return state;
}

function changeFilterField(state, filterId, fieldName) {
    let changedfield = '';
    state.availableFields.map((field, key) => {
        if (field.key === fieldName) {
            changedfield = field;
        }
    });

    state.filters[filterId] = getFilter(changedfield);
    return state;
}

function getValue(value, operator) {
    const isArray = Array.isArray(value);
    if (operator == operators.BETWEEN || operator == operators.NOT_BETWEEN) {
        return isArray ? value : [value, null];
    } else {
        return isArray ? value[0] : value;
    }
}

function changeFilterOperator(state, filterId, operator) {
    return state.updateIn(['filters', filterId], (filter) => {
        return filter
            .set('operator', operator)
            .set('value', getValue(filter.value, operator));
    });
}

function changeFilterValue(state, filterId, value) {
    state.filters[filterId].value = value;
    return state;
}

function updateAvailableField(state, availableField) {
    availableField.map((field, key) => {
        field.widget = fields.checkWidget(field.fieldType);
    });
    state.selectedFields = [...availableField];
    state.availableFields = availableField;
    return state;
}
function resetAPIBody(newState, apiType) {
    let state = newState;
    newState = {};
    newState.pageNo = config.apiBody.pageNo;
    newState.pageSize = config.apiBody.pageSize;
    if (apiType !== undefined) {
        newState.type = apiType;
    }
    if (state.type) {
        newState.type = state.type;
    }
    if (state.sortBy !== undefined) {
        newState.sortBy = state.sortBy;
    }
    return newState;
}

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];
    let filterStateUpdate = '';
    let apiBodyField = state.apiBody;

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu,
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter((item) => item !== action.menu.id);
                    trigger = trigger.filter((item) => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = state.isTrigger.indexOf(action.menu.id);
                trigger = triggerIndex === -1 ? [action.menu.id] : [];
                open = triggerIndex === -1 ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter((item) => item !== action.menu.id);
                    trigger = trigger.filter((item) => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return { ...state };
        case actionTypes.FULL_SCREEN:
            return {
                ...state,
                isFullScreen: !state.isFullScreen,
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false,
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout,
            };
        case actionTypes.UPDATE_API_FIELDS:
            return {
                ...state,
                apiFields: action.apiFields,
            };
        case actionTypes.UPDATE_SYSTEM_PARAM:
            return {
                ...state,
                systemParam: action.systemParam,
            };
        case actionTypes.UPDATE_API:
            return {
                ...state,
                apiUri: action.apiUri,
                apiType: action.apiType,
            };
        case actionTypes.UPDATE_API_BODY:
            return {
                ...state,
                apiBody: action.apiBody,
            };
        case actionTypes.RESET_API_BODY:
            apiBodyField = resetAPIBody(state.apiBody, action.apiType);
            return {
                ...state,
                apiBody: apiBodyField,
            };

        case actionTypes.RESET_FORM_FIELD:
            let formData = {};
            return {
                ...state,
                formData: formData,
            };
        case actionTypes.UPDATE_FORM_FIELD:
            return {
                ...state,
                formData: action.formData,
            };

        case actionTypes.RESET_ERROR_LIST:
            let errorList = [];
            return {
                ...state,
                errorList: errorList,
            };
        case actionTypes.UPDATE_ERROR_LIST:
            return {
                ...state,
                errorList: action.errorList,
            };
        case actionTypes.UPDATE_API_PAGE_NUMBER:
            apiBodyField.pageNo = action.pageNo;
            return {
                ...state,
                apiBody: apiBodyField,
            };
        case actionTypes.UPDATE_API_PAGE_SIZE:
            apiBodyField.pageSize = action.pageSize;
            return {
                ...state,
                apiBody: apiBodyField,
            };
        case actionTypes.UPDATE_API_RESULT:
            return {
                ...state,
                apiResult: action.apiResult,
            };
        case actionTypes.UPDATE_API_DATA:
            return {
                ...state,
                apiResult: action.payload,
            };

        case actionTypes.UPDATE_PREV_PATH:
            return {
                ...state,
                prevPath: action.prevPath,
            };
        case actionTypes.UPDATE_LOADING_STATUS:
            return {
                ...state,
                loading: action.loading,
            };
        case actionTypes.MODEL_STATUS:
            return {
                ...state,
                showModal: action.showModal,
            };
        case actionTypes.UPDATE_MODEL:
            return {
                ...state,
                modelBody: action.modelBody,
            };
        case actionTypes.RESET_MODEL:
            return {
                ...state,
                modelBody: action.modelBody,
                showModal: action.showModal,
            };
        case actionTypes.NEED_LOGIN:
            return {
                ...state,
                needLogin: action.needLogin,
            };
        case actionTypes.API_ERROR:
            let prevPath = state.prevPath;
            let needLogin = state.needLogin;
            let showAlertModal = false;
            let alertModalTitle = '';
            let alertModalOptions = null;

            if (action.errorResponse.status === 500 || action.errorResponse === "api_issue") {
                showAlertModal = true;
                alertModalTitle = i18n.t('error.api');
            }
            if (action.errorResponse.status === 413) {
                showAlertModal = true;
                alertModalTitle = i18n.t('error.413');
            }
            if (action.errorResponse.status === 403) {
                showAlertModal = true;
                alertModalTitle = i18n.t('error.403');
                // forbidden = true;
            }
            if (action.errorResponse.status === 401) {
                if (action.pathname) {
                    prevPath = action.pathname;
                }
                needLogin = true;
            }
            if (action.errorResponse.status === 400) {
                let errorMessage = i18n.t('error.submit');
                (
                    action.errorResponse.data
                    && action.errorResponse.data !== ''
                    && action.errorResponse.data.error === undefined
                ) &&
                    action.errorResponse.data.map((error) => {
                        errorMessage =
                            errorMessage +
                            '\n' +
                            i18n.t(error.labelId) +
                            ': ' +
                            i18n.t(error.messageId, { lineNo: error.messageParamMap ? error.messageParamMap.lineNo : "", column: error.messageParamMap ? error.messageParamMap.column : "", code: error.messageParamMap ? error.messageParamMap.code : "" });
                    });
                showAlertModal = true;
                alertModalTitle = errorMessage;
            }

            return {
                ...state,
                prevPath: prevPath,
                needLogin: needLogin,
                showAlertModal: showAlertModal,
                alertModalTitle: alertModalTitle,
                alertModalOptions: alertModalOptions,
                onHideAlertModal: null,
            };
        case actionTypes.UPDATE_REGION_CODE:
            localStorage.setItem('regionCode', action.regionCode);
            return {
                ...state,
                regionCode: action.regionCode,
            };
        case actionTypes.UPDATE_LANGUAGE_CODE:
            localStorage.setItem('languageCode', action.languageCode);
            return {
                ...state,
                languageCode: action.languageCode,
            };
        case actionTypes.UPDATE_HEADERS:
            let headers = { ...state.headers };
            headers[action.key] = action.value;
            return {
                ...state,
                headers: headers,
            };

        //Filter Actions
        case actionTypes.SELECT_FIELD:
            filterStateUpdate = selectField(state.filterState, action.field);
            filterStateUpdate.filters.map((filter) => {
                if (filter.field.key.split('.')[1] !== undefined) {
                    apiBodyField[filter.field.key.split('.')[0]] = {};
                    apiBodyField[filter.field.key.split('.')[0]][
                        filter.field.key.split('.')[1]
                    ] = filter.value;
                } else {
                    apiBodyField[filter.field.key.split('.')[0]] = filter.value;
                }
            });
            return {
                ...state,
                filterState: filterStateUpdate,
                apiBody: apiBodyField,
            };

        case actionTypes.UNSELECT_FIELD:
            filterStateUpdate = unselectField(state.filterState, action.field);
            filterStateUpdate.filters.map((filter) => {
                if (filter.field.key.split('.')[1] !== undefined) {
                    apiBodyField[filter.field.key.split('.')[0]] = {};
                    apiBodyField[filter.field.key.split('.')[0]][
                        filter.field.key.split('.')[1]
                    ] = filter.value;
                } else {
                    apiBodyField[filter.field.key.split('.')[0]] = filter.value;
                }
            });
            return {
                ...state,
                filterState: filterStateUpdate,
                apiBody: apiBodyField,
            };

        case actionTypes.ADD_FILTER:
            filterStateUpdate = addFilter(state.filterState);
            return {
                ...state,
                filterState: filterStateUpdate,
            };

        case actionTypes.REMOVE_FILTER:
            apiBodyField = resetAPIBody(state.apiBody);
            filterStateUpdate = removeFilter(
                state.filterState,
                action.filterId
            );
            filterStateUpdate.filters.map((filter) => {
                if (filter.field.key.split('.')[1] !== undefined) {
                    apiBodyField[filter.field.key.split('.')[0]] = {};
                    apiBodyField[filter.field.key.split('.')[0]][
                        filter.field.key.split('.')[1]
                    ] = filter.value;
                } else {
                    apiBodyField[filter.field.key.split('.')[0]] = filter.value;
                }
            });

            return {
                ...state,
                filterState: filterStateUpdate,
                apiBody: apiBodyField,
            };

        case actionTypes.CLEAR_FILTERS:
            apiBodyField = resetAPIBody(state.apiBody);
            filterStateUpdate = clearFilters(state.filterState);
            return {
                ...state,
                filterState: filterStateUpdate,
                apiBody: apiBodyField,
            };

        case actionTypes.CHANGE_FILTER_FIELD:
            apiBodyField = resetAPIBody(state.apiBody);
            filterStateUpdate = changeFilterField(
                state.filterState,
                action.filterId,
                action.fieldName
            );
            filterStateUpdate.filters.map((filter) => {
                if (filter.field.key.split('.')[1] !== undefined) {
                    apiBodyField[filter.field.key.split('.')[0]] = {};
                    apiBodyField[filter.field.key.split('.')[0]][
                        filter.field.key.split('.')[1]
                    ] = filter.value;
                } else {
                    apiBodyField[filter.field.key.split('.')[0]] = filter.value;
                }
            });
            return {
                ...state,
                filterState: filterStateUpdate,
                apiBody: apiBodyField,
            };

        case actionTypes.CHANGE_FILTER_OPERATOR:
            apiBodyField = resetAPIBody(state.apiBody);
            filterStateUpdate = changeFilterOperator(
                state.filterState,
                action.filterId,
                action.operator
            );
            filterStateUpdate.filters.map((filter) => {
                if (filter.field.key.split('.')[1] !== undefined) {
                    apiBodyField[filter.field.key.split('.')[0]] = {};
                    apiBodyField[filter.field.key.split('.')[0]][
                        filter.field.key.split('.')[1]
                    ] = filter.value;
                } else {
                    apiBodyField[filter.field.key.split('.')[0]] = filter.value;
                }
            });
            return {
                ...state,
                filterState: filterStateUpdate,
                apiBody: apiBodyField,
            };

        case actionTypes.CHANGE_FILTER_VALUE:
            apiBodyField = resetAPIBody(state.apiBody);
            filterStateUpdate = changeFilterValue(
                state.filterState,
                action.filterId,
                action.value
            );
            filterStateUpdate.filters.map((filter) => {
                if (filter.field.key.split('.')[1] !== undefined) {
                    apiBodyField[filter.field.key.split('.')[0]] = {};
                    apiBodyField[filter.field.key.split('.')[0]][
                        filter.field.key.split('.')[1]
                    ] = filter.value;
                } else {
                    apiBodyField[filter.field.key.split('.')[0]] = filter.value;
                }
            });
            return {
                ...state,
                filterState: filterStateUpdate,
                apiBody: apiBodyField,
            };

        case actionTypes.UPDATE_AVAILABLE_FIELD:
            filterStateUpdate = updateAvailableField(
                state.filterState,
                action.value
            );
            return {
                ...state,
                filterState: filterStateUpdate,
            };
        case actionTypes.SHOW_ALERT_MODAL:
            return {
                ...state,
                showAlertModal: true,
                alertModalTitle: action.title,
                onHideAlertModal: action.onHide,
                alertModalOptions: action.options,
            };
        case actionTypes.HIDE_ALERT_MODAL:
            return {
                ...state,
                showAlertModal: false,
            };
        default:
            return state;
    }
};

export default reducer;
