import React from 'react';

const Signin = React.lazy(() => import('./App/pages/authentication/signIn'));
const SigninRedirect = React.lazy(() => import('./App/pages/authentication/signInRedirect'));
const Signout = React.lazy(() => import('./App/pages/authentication/signOut'));
const Forbidden = React.lazy(() => import('./App/pages/forbidden'));

const route = [
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/signin-redirect', exact: true, name: 'Signin Redirect', component: SigninRedirect },
    { path: '/auth/signout', exact: true, name: 'Signout', component: Signout },
    { path: '/forbidden', exact: true, name: 'Forbidden', component: Forbidden },
];

export default route;