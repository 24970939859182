import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import config from '../../config';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https:/react.'i18next.c'm/guides/multiple-translation-files)
let fallbackLng = {};
config.languages.map((language) => {
  return fallbackLng[language] = [language];
})
Object.entries(fallbackLng).map(([index]) => {
  return index !== 'en' && fallbackLng[index].push('en');
})
fallbackLng['en-GB'] = ['zh-HK', 'en'];
fallbackLng.default = ['en'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: config.languageCode,
    fallbackLng: fallbackLng,
    debug: false,
    supportedLngs: config.languages,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: window.location.origin + config.basename + 'assets/locales/{{lng}}/translation.json'
    },
  });
export default i18n;